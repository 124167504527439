import PropTypes from "prop-types";
import React, { Fragment } from "react";

const Textarea = ({ label, id, name, rows, placeholder, required, onChange, onKeyDown, asFragment, className }) => {
	let Tag = "div";
	if (asFragment) {
		Tag = Fragment;
	}

	return (
		<Tag>
			<label className="mb-1 block text-left text-xs" htmlFor={id}>
				{label}
				{required && "*"}
			</label>

			<textarea
				cols="50"
				rows={rows}
				className={`w-full appearance-none border bg-white p-4 text-lg leading-tight focus:border-transparent focus:outline-none focus:ring-1 focus:ring-inset focus:ring-black ${
					className ? className : ""
				}`}
				id={id}
				name={name}
				placeholder={placeholder}
				required={required}
				onChange={onChange}
				onKeyDown={onKeyDown}
			/>
		</Tag>
	);
};

export default Textarea;

Textarea.propTypes = {
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	rows: PropTypes.string,
	required: PropTypes.bool,
	onChange: PropTypes.func,
	onKeyDown: PropTypes.func,
	asFragment: PropTypes.bool,
	className: PropTypes.string,
};

Textarea.defaultProps = {
	rows: "4",
	required: null,
	onChange: null,
	onKeyDown: null,
	asFragment: null,
	className: null,
};
