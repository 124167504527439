import { CheckIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import React, { useState } from "react";
import * as containerStyles from "./CheckboxCard.module.css";

function CheckboxCard({ name, value, id, label, svg }) {
	const [isChecked, setIsChecked] = useState(false);

	const handleChange = (e) => {
		e.preventDefault();
		setIsChecked(!isChecked);
	};

	return (
		<button
			className="relative flex w-full self-stretch bg-white focus:outline-none focus:ring-1 focus:ring-black focus:ring-offset-1"
			onClick={handleChange}
			type="button"
		>
			<input
				className="hidden"
				type="checkbox"
				name={name}
				value={value}
				id={id}
				checked={isChecked}
				onChange={handleChange}
			/>
			{isChecked && <CheckIcon className="absolute top-2 right-2 h-8 w-8 rounded-full bg-black p-2 text-white" />}
			<label
				className={`relative w-full cursor-pointer self-stretch border border-gray-300 p-4 text-center transition-colors duration-300 ${
					isChecked ? containerStyles.active : "bg-pattern"
				}`}
				htmlFor={id}
			>
				<div className=" svg-checkbox-container">
					<div className="text-center">{svg}</div>
					<div>{label}</div>
				</div>
			</label>
		</button>
	);
}

export default CheckboxCard;

CheckboxCard.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	svg: PropTypes.node.isRequired,
};
