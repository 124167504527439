/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types";
import React from "react";

const Wizard = ({ currentStep, children }) => (
	<div>
		{children.map((child, i) => {
			if (i === currentStep) {
				return <div key={`wizard-step-${i}`}>{child}</div>;
			}
			return (
				<div className="hidden" key={`wizard-step-${i}`}>
					{child}
				</div>
			);
		})}
	</div>
);

export default Wizard;

Wizard.propTypes = {
	currentStep: PropTypes.number.isRequired,
	children: PropTypes.node.isRequired,
};
